/* eslint-env jquery, browser */
import $ from 'jquery';
import autocomplete from 'jquery-ui/ui/widgets/autocomplete';
import tether from 'tether';
import '../assets/plugins/bootstrap/js/bootstrap.min';
import '../javascripts/jquery.slimscroll';
import '../javascripts/waves';
import '../javascripts/sidebarmenu';
import '../assets/plugins/sticky-kit-master/dist/sticky-kit.min';
import datatables from 'datatables.net-dt';
import 'datatables.net-buttons';
import * as custom from '../javascripts/custom';
import sweetalert from '../assets/plugins/sweetalert/sweetalert.min';
import '../assets/plugins/sweetalert/jquery.sweet-alert.custom';
import datepicker from '../assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min';
import '../assets/plugins/jStorage/jstorage.min';
import moment from '../assets/plugins/moment/min/moment.min';
import '../assets/plugins/bootstrap-select/bootstrap-select.min';
import Mustache from '../assets/plugins/mustache/mustache.min';
import Dropzone from '../assets/plugins/dropzone-5.5.0/dist/dropzone';
import '../assets/plugins/scheletrone/jquery.scheletrone';
import sjcl from 'sjcl';

window.$ = $;
window.jQuery = $;
window.Tether = tether;
window.capitalizeAllWords = custom.capitalizeAllWords;
window.swal = sweetalert;
window.$.datepicker = datepicker;
window.$.autocomplete = autocomplete;
window['datatables.net'] = datatables['datatables.net'];
window.moment = moment;
window.Dropzone = Dropzone;
window.Mustache = Mustache;
window.sjcl = sjcl;
