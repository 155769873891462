/* eslint-disable prefer-arrow-callback, prefer-template, prefer-spread,
prefer-destructuring, object-shorthand, func-names, no-loop-func, comma-dangle,
prefer-rest-params */
/* eslint-env jquery, browser */
$(function () {
  $(function () {
    $('.preloader').fadeOut();
  });
  jQuery(document).on('click', '.mega-dropdown', function (e) {
    e.stopPropagation();
  });
  const set = function () {
    const width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
    const topOffset = 70;
    if (width < 1170) {
      $('body').addClass('mini-sidebar');
      $('.navbar-brand span.logo-full').hide();
      $('#powered_by_branding').hide();
      $('.navbar-brand span.logo-icon').show();
      $('.scroll-sidebar, .slimScrollDiv').css('overflow-x', 'visible').parent().css('overflow', 'visible');
      $('.sidebartoggler i').addClass('ti-menu');
    } else {
      $('body').removeClass('mini-sidebar');
      $('.navbar-brand span.logo-icon').hide();
      $('.navbar-brand span.logo-full').show();
      $('#powered_by_branding').show();
      $('.sidebartoggler i').removeClass('ti-menu');
    }

    let height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
    height -= topOffset;
    if (height < 1) height = 1;
    if (height > topOffset) {
      $('.page-wrapper').css('min-height', (height) + 'px');
    }
  };
  $(window).ready(set);
  $(window).on('resize', set);
  $('.sidebartoggler').on('click', function () {
    if ($('body').hasClass('mini-sidebar')) {
      $('body').trigger('resize');
      $('.scroll-sidebar, .slimScrollDiv').css('overflow', 'hidden').parent().css('overflow', 'visible');
      $('body').removeClass('mini-sidebar');
      $('.navbar-brand span.logo-icon').hide();
      $('.navbar-brand span.logo-full').show();
      $('#powered_by_branding').show();
      $('.sidebartoggler i').addClass('ti-menu');
    } else {
      $('body').trigger('resize');
      $('.scroll-sidebar, .slimScrollDiv').css('overflow-x', 'visible').parent().css('overflow', 'visible');
      $('body').addClass('mini-sidebar');
      $('.navbar-brand span.logo-full').hide();
      $('#powered_by_branding').hide();
      $('.navbar-brand span.logo-icon').show();
      $('.sidebartoggler i').removeClass('ti-menu');
    }
  });

  $('.fix-header .topbar').stick_in_parent({

  });

  $('.nav-toggler').click(function () {
    $('body').toggleClass('show-sidebar');
    $('.nav-toggler i').toggleClass('ti-menu');
    $('.nav-toggler i').addClass('ti-close');
  });

  $('.sidebartoggler').on('click', function () {
    $('.sidebartoggler i').toggleClass('ti-menu');
  });

  $('.right-side-toggle').click(function () {
    $('.right-sidebar').slideDown(50);
    $('.right-sidebar').toggleClass('shw-rside');
  });

  $(function () {
    const url = window.location.pathname;
    $('ul#sidebarnav a').filter(function () {
      return $(this).attr('href') === url;
    }).addClass('active').parents('li')
      .addClass('active selected');
  });

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  $(function () {
    $('[data-toggle="popover"]').popover();
  });

  $(function () {
    $('#sidebarnav').metisMenu();
  });

  $('.scroll-sidebar').slimScroll({
    position: 'left',
    size: '5px',
    height: '100%',
    color: '#dcdcdc'
  });

  $('.message-center').slimScroll({
    position: 'right',
    size: '5px',

    color: '#dcdcdc'
  });

  $('.aboutscroll').slimScroll({
    position: 'right',
    size: '5px',
    height: '80',
    color: '#dcdcdc'
  });
  $('.message-scroll').slimScroll({
    position: 'right',
    size: '5px',
    height: '570',
    color: '#dcdcdc'
  });

  $('.chat-box').slimScroll({
    position: 'right',
    size: '5px',
    height: '470',
    color: '#dcdcdc'
  });

  $('.slimscrollright').slimScroll({
    height: '100%',
    position: 'right',
    size: '5px',
    color: '#dcdcdc'
  });

  $('body').trigger('resize');
  $('.list-task li label').click(function () {
    $(this).toggleClass('task-done');
  });

  $('#to-recover').on('click', function () {
    $('#loginform').slideUp();
    $('#recoverform').fadeIn();
  });

  $(document).on('click', '.card-actions a', function (e) {
    e.preventDefault();
    if ($(this).hasClass('btn-close')) {
      $(this).parent().parent().parent()
        .fadeOut();
    }
  });

  $(document).on('click', '.toggle-password', function () {
    $(this).toggleClass('mdi-eye mdi-eye-off');
    const input = $($(this).attr('toggle'));
    if (input.attr('type') === 'password') {
      input.attr('type', 'text');
    } else {
      input.attr('type', 'password');
    }
  });

  (function ($, window, document) {
    const panelSelector = '[data-perform="card-collapse"]';
    $(panelSelector).each(function () {
      const $this = $(this);
      const parent = $this.closest('.card');
      let wrapper = parent.find('.card-block');
      let collapseOpts = {
        toggle: false
      };
      if (!wrapper.length) {
        wrapper = parent.children('.card-heading').nextAll().wrapAll('<div/>').parent()
          .addClass('card-block');
        collapseOpts = {};
      }
      wrapper.collapse(collapseOpts).on('hide.bs.collapse', function () {
        $this.children('i').removeClass('ti-minus').addClass('ti-plus');
      }).on('show.bs.collapse', function () {
        $this.children('i').removeClass('ti-plus').addClass('ti-minus');
      });
    });

    $(document).on('click', panelSelector, function (e) {
      e.preventDefault();
      const parent = $(this).closest('.card');
      const wrapper = parent.find('.card-block');
      wrapper.collapse('toggle');
    });
  }(jQuery, window, document));
});

// eslint-disable-next-line import/prefer-default-export
export function capitalizeAllWords(string) {
  return string.replace(/(?:^|\s|["'([{])+\S/g, function (match) {
    return match.toUpperCase();
  });
}

/* Script to fix body scroll issue when two Bootstrap modals open one after the
other (affects all modals on the site) */
const oldJqTrigger = jQuery.fn.trigger;
jQuery.fn.trigger = function () {
  if (arguments && arguments.length > 0) {
    if (typeof arguments[0] === 'object') {
      if (typeof arguments[0].type === 'string') {
        if (arguments[0].type === 'show.bs.modal') {
          const ret = oldJqTrigger.apply(this, arguments);
          if ($('.modal:visible').length) {
            $('.modal-backdrop.in').first().css('z-index', parseInt($('.modal:visible').last().css('z-index'), 10) + 10);
            $(this).css('z-index', parseInt($('.modal-backdrop.in').first().css('z-index'), 10) + 10);
          }
          return ret;
        }
      }
    } else if (typeof arguments[0] === 'string') {
      if (arguments[0] === 'hidden.bs.modal') {
        if ($('.modal:visible').length) {
          $('.modal-backdrop').first().css('z-index', parseInt($('.modal:visible').last().css('z-index'), 10) - 10);
          $('body').addClass('modal-open');
        }
      }
    }
  }
  return oldJqTrigger.apply(this, arguments);
};
